'use client';
import { Minus, Plus } from 'phosphor-icons';
import { useEffect, useState } from 'react';
import { RichText } from '../RichText/RichText';

type Headings = 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type AccordionItem = {
  title: string;
  content: string;
};

type AccordionProps = {
  className?: string;
  headingLevel?: Headings;
  items: AccordionItem[];
  onAccordionAction?: (heading: string, action: 'open' | 'close') => void;
};

export const Accordion = ({
  className = '',
  headingLevel = 'h3',
  items,
  onAccordionAction,
}: AccordionProps) => {
  const HeadingTag = headingLevel;
  const accordionIconClassNames =
    'shrink-0 rounded p-1 lg:mr-6 ml-8 transition group-hover:bg-grey-200 group-focus-visible:bg-grey-200';
  const [visiblePanels, setVisiblePanels] = useState<Record<string, boolean>>(
    {}
  );

  useEffect(() => {
    // need to set any links that are possibly within the content to -1 tabindex, since the content is hidden on mount
    items.forEach((_, index) => setLinksTabIndex(index, '-1'));
  }, [items]);

  function setLinksTabIndex(elementIndex: number, tabIndex: string) {
    const contentElement = document.getElementById(
      `accordion-panel-${elementIndex}`
    );
    const links = contentElement?.querySelectorAll('a');
    links?.forEach((link) => link.setAttribute('tabindex', tabIndex));
  }

  function togglePanel(index: number) {
    visiblePanels[index] ? closePanel(index) : openPanel(index);
  }

  function openPanel(index: number) {
    setVisiblePanels((prevState) => ({
      ...prevState,
      [index]: true,
    }));

    setLinksTabIndex(index, '0');
    onAccordionAction?.(items[index].title, 'open');
  }

  function closePanel(index: number) {
    setVisiblePanels((prevState) => ({
      ...prevState,
      [index]: false,
    }));

    setLinksTabIndex(index, '-1');
    onAccordionAction?.(items[index].title, 'close');
  }

  function handleKeyboardNavigation(
    index: number,
    e: React.KeyboardEvent<HTMLButtonElement>
  ) {
    if (e.key === 'Escape') {
      closePanel(index);
    } else if (e.key === 'ArrowDown') {
      const nextHeadingIndex = index === items.length - 1 ? 0 : index + 1;
      const nextItem = document
        .getElementById(`accordion-heading-${nextHeadingIndex}`)
        ?.getElementsByTagName('button')[0];

      if (nextItem) {
        nextItem.focus();
      }
    } else if (e.key === 'ArrowUp') {
      const previousHeadingIndex = index === 0 ? items.length - 1 : index - 1;
      const previousItem = document
        .getElementById(`accordion-heading-${previousHeadingIndex}`)
        ?.getElementsByTagName('button')[0];

      if (previousItem) {
        previousItem.focus();
      }
    }
  }

  return (
    <div className={className}>
      {items.map((item, index) => {
        const headingId = `accordion-heading-${index}`;
        const panelId = `accordion-panel-${index}`;

        return (
          <div
            key={item.title}
            className="border-b border-grey-200 pt-4 lg:pt-6"
          >
            <HeadingTag id={headingId}>
              <button
                aria-expanded={visiblePanels[index]}
                aria-controls={panelId}
                className="body-bold group flex w-full items-center justify-between text-left text-grey-900 outline-offset-2 outline-blue-dark focus-visible:outline-2"
                onClick={() => togglePanel(index)}
                onKeyDown={(e) => handleKeyboardNavigation(index, e)}
              >
                {item.title}
                {visiblePanels[index] ? (
                  <Minus size={28} className={accordionIconClassNames} />
                ) : (
                  <Plus size={28} className={accordionIconClassNames} />
                )}
              </button>
            </HeadingTag>
            <div
              id={panelId}
              aria-labelledby={headingId}
              aria-hidden={!visiblePanels[index]}
              className="grid grid-rows-[1fr] py-2 transition-[grid-template-rows] duration-500 aria-hidden:grid-rows-[0fr] lg:py-3"
            >
              <RichText
                html={item.content}
                className="overflow-hidden pr-14 lg:pr-20"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

import { Star, StarHalf } from 'phosphor-icons';
import { newArrayOfSize } from 'utils';

type StarsProps = {
  value: number;
  max?: number;
  width?: number;
  height?: number;
  ariaLabel?: string;
};

const MAX_DEFAULT = 5;
const WIDTH_DEFAULT = 25;
const HEIGHT_DEFAULT = 25;

export const Stars = ({
  value,
  ariaLabel = `${value} out of 5 stars`,
  max = MAX_DEFAULT,
  width = WIDTH_DEFAULT,
  height = HEIGHT_DEFAULT,
}: StarsProps) => {
  const stars = newArrayOfSize(max).map((_, index) => {
    if (index < Math.floor(value)) {
      return (
        <Star
          // index is okay to use as a key here since the items will never change order, and there is no other way to differentiate items
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          width={width}
          height={height}
          className="fill-current"
          data-test="star-full"
          weight="fill"
        />
      );
    } else if (index < value) {
      return (
        <StarHalf
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          width={width}
          height={height}
          className="fill-current"
          data-test="star-half"
          weight="fill"
        />
      );
    } else {
      return (
        <Star
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          width={width}
          height={height}
          className="fill-current"
          data-test="star-empty"
          aria-hidden
        />
      );
    }
  });

  return (
    <div
      role="img"
      aria-label={ariaLabel}
      className="flex"
      data-test="stars-rating"
      data-test-score={value}
    >
      {stars}
    </div>
  );
};

'use client';
export {
  ArrowRight,
  BellSimple,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  Chats,
  CheckCircle,
  Circuitry,
  CopySimple,
  Cube,
  Folder,
  GearSix,
  GraduationCap,
  Headphones,
  Heart,
  HeartStraight,
  ImageSquare,
  InstagramLogo,
  Layout,
  List,
  MagicWand,
  MagnifyingGlass,
  Medal,
  MegaphoneSimple,
  Minus,
  Notepad,
  Pause,
  Percent,
  Play,
  Plug,
  Plus,
  PlusCircle,
  PuzzlePiece,
  RocketLaunch,
  ShieldCheck,
  ShoppingCart,
  SignIn,
  SignOut,
  SquaresFour,
  Star,
  StarHalf,
  ThumbsUp,
  Ticket,
  TwitterLogo,
  Upload,
  UserCircle,
  UserPlus,
  WarningOctagon,
  Wrench,
  X,
  XCircle,
  YoutubeLogo,
} from '@phosphor-icons/react';

import { PropsWithChildren } from 'react';

type UserAssetsCollectionListProps = {
  title: string;
};
export const UserAssetsCollectionList = ({
  title,
  children,
}: PropsWithChildren<UserAssetsCollectionListProps>) => {
  return (
    <div className="bg-grey-100 py-10">
      <div className="container">
        <h2 className="mb-8 text-xl">{title}</h2>
        <div className="flex flex-wrap">{children}</div>
      </div>
    </div>
  );
};

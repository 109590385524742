import { interpolateStringWithData } from 'utils';

type ToggleMoreItemsButtonProps = {
  onClick: () => void;
  excessItems: number;
  label: string;
  icon: any;
};

export const ToggleMoreItemsButton = ({
  onClick,
  excessItems,
  label,
  icon: IconComponent,
}: ToggleMoreItemsButtonProps) => {
  return (
    <button className="caption flex items-center text-base" onClick={onClick}>
      {interpolateStringWithData(label, {
        number: excessItems.toString(),
      })}
      <IconComponent className="ml-1 mt-0.5" />
    </button>
  );
};

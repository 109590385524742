import c from 'classnames';

type TooltipProps = {
  label: string;
  className?: string;
};
export const Tooltip = ({ label, className = '' }: TooltipProps) => {
  return (
    <div
      className={c(
        'rounded-sm bg-grey-700 px-2.5 py-2.5 text-xs text-white',
        className
      )}
    >
      {label}
    </div>
  );
};
